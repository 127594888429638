import { fetchLangflowMessages } from "./api/langflowProxy";

/**
 * Fetches chat history from Langflow API and parses it to the application's format
 * @param {string} userEmail - The email of the current user
 * @returns {Array} Array of chat objects in the application format
 */
async function getLangflowHistory(userEmail) {
  try {
    // Use the proxy service to fetch data
    const data = await fetchLangflowMessages(userEmail);

    // Transform the data to match the expected format
    const chats = [];

    // Process each chat group in the response
    Object.entries(data).forEach(([chatId, messages]) => {
      // Skip empty conversations
      if (!messages || messages.length === 0) return;

      // Sort messages by timestamp to ensure chronological order
      const sortedMessages = [...messages].sort(
        (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
      );

      // Format messages for our application
      const formattedMessages = sortedMessages.map((msg, index) => {
        // Convert timestamp to our date format
        // Handle the case where timestamp might be in the future (2025)
        const date = new Date(msg.timestamp);
        // Ensure we're using local time formatting that matches the app's expected format
        const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")} ${String(date.getHours()).padStart(2, "0")}:${String(date.getMinutes()).padStart(2, "0")}:${String(date.getSeconds()).padStart(2, "0")}`;

        return {
          id: `${chatId}${String(index).padStart(5, "0")}`,
          date: formattedDate,
          role: msg.sender === "User" ? "user" : "assistant",
          content: msg.text,
          feedback: -1,
        };
      });

      // Create the historic text
      const historic = formattedMessages
        .map((msg) => `${msg.role}:${msg.content}`)
        .join("\n");

      // Create summary from first user message (truncated if needed)
      const firstUserMsg = formattedMessages.find((msg) => msg.role === "user");
      const summary = firstUserMsg
        ? firstUserMsg.content.length > 15
          ? firstUserMsg.content.substring(0, 12) + "..."
          : firstUserMsg.content
        : "Conversa com DAGSol";

      // Add the chat to our array
      chats.push({
        protocol: chatId,
        summary,
        pinned: false,
        historic,
        knowledge: "",
        type: "dagsol",
        messages: formattedMessages,
      });
    });

    return chats;
  } catch (error) {
    console.error("Error fetching Langflow history:", error);
    return [];
  }
}

export default getLangflowHistory;
