import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import Ellipsis from "../Ellipisis/Ellipisis";
import { useChatContext } from "../../../contexts/ChatContext";
import { getHistoric } from "../../../server/getHistoric";
import { useMeetingMinute } from "../../../contexts/MeetingMinuteContext";

const getRandomSkeletonCount = () => Math.floor(Math.random() * 13) + 8;

const getRandomWidthClass = () => {
  const widths = ["w-1/2", "w-2/3", "w-[90%]"];
  return widths[Math.floor(Math.random() * widths.length)];
};

/**
 * Componente que exibe o histórico de conversas do chat.
 *
 * @component
 * @param {Object[]} history - Lista de conversas agrupadas por data.
 * @returns {JSX.Element} O componente de histórico de chat.
 */
export default function ChatHistory({ history }) {
  console.log("[ChatHistory] Component rendered with history:", history);

  const {
    chatData,
    setChatData,
    setMessages,
    setCurrentChatProtocol,
    setCurrentChatHistoric,
    setCurrentChatKnowledge,
    setShowReportScreen,
    setCurrentSelection,
    setCurrentChatType,
    currentHistoryPagination,
    setCurrentHistoryPagination,
    resetPagination,
    setCurrentChatSummary,
    setShowDAGSolScreen,
  } = useChatContext();

  const { setShowMeetingMinutesScreen } = useMeetingMinute();

  const [ellipisisVisible, setEllipisisVisible] = useState(null);
  const [lastScrollPosition, setLastScrollPosition] = useState(0);
  const [scrollCheckpoint, setScrollCheckpoint] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const containerRef = useRef(null);
  const [skeletonCount, setSkeletonCount] = useState(0);

  /**
   * Efeito que gerencia a rolagem do histórico de chat e carrega mais dados quando o usuário atinge 75% de rolagem.
   */
  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current && !loading && !isFetching) {
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
        const scrollPosition = scrollTop / (scrollHeight - clientHeight);
        const currentScrollCheckpoint = Math.floor((scrollPosition * 100) / 75);

        if (
          scrollPosition > 0.75 &&
          currentScrollCheckpoint > scrollCheckpoint
        ) {
          console.log(
            "[ChatHistory] Scroll threshold reached, loading more history"
          );
          setLastScrollPosition(scrollTop);
          setScrollCheckpoint(currentScrollCheckpoint);
          onScrollPast75Percent();
        }
      }
    };

    const currentContainerRef = containerRef.current;
    currentContainerRef.addEventListener("scroll", handleScroll);

    return () => {
      if (currentContainerRef) {
        currentContainerRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [lastScrollPosition, scrollCheckpoint, loading, isFetching]);

  /**
   * Função chamada ao atingir 75% de rolagem. Carrega mais histórico de chat.
   */
  const onScrollPast75Percent = () => {
    if (!loading && currentHistoryPagination !== null) {
      console.log(
        "[ChatHistory] Loading more history, current pagination:",
        currentHistoryPagination
      );
      setIsFetching(true);
      const newPagination = currentHistoryPagination + 1;

      getHistoric(newPagination)
        .then((data) => {
          console.log("[ChatHistory] Received historical data:", data);

          const existingIds = new Set(
            chatData.history.map((chat) => chat.protocol)
          );
          const newChats = data.history.filter(
            (chat) => !existingIds.has(chat.protocol)
          );
          console.log("[ChatHistory] Filtered new chats:", newChats.length);

          if (newChats.length === 0) {
            console.log("[ChatHistory] No new chats to add");
            setLoading(false);
            setIsFetching(false);
            return;
          }

          if (data.detail) {
            console.log(
              "[ChatHistory] Received detail in response, stopping pagination"
            );
            setCurrentHistoryPagination(null);
            setLoading(false);
            setIsFetching(false);
            return;
          }

          setChatData((prevChatData) => {
            const updatedHistory = [
              ...prevChatData.history,
              ...newChats.filter(
                (newChat) =>
                  !prevChatData.history.some(
                    (chat) => chat.protocol === newChat.protocol
                  )
              ),
            ];
            console.log(
              "[ChatHistory] Updated history with new chats, total count:",
              updatedHistory.length
            );
            return {
              ...prevChatData,
              history: updatedHistory,
            };
          });

          setScrollCheckpoint(0);
          setLastScrollPosition(0);
        })
        .catch((error) => {
          console.error("[ChatHistory] Error loading more history:", error);
          setCurrentHistoryPagination(null);
          setLoading(false);
          setIsFetching(false);
        })
        .finally(() => {
          setLoading(false);
          setIsFetching(false);
          setCurrentHistoryPagination(newPagination);
        });
    }
  };

  /**
   * Função chamada ao selecionar uma conversa.
   *
   * @param {Object} selectedChat - O chat selecionado pelo usuário.
   */
  const handleConversationSelect = (selectedChat) => {
    console.log("[ChatHistory] Conversation selected:", selectedChat);

    if (Array.isArray(selectedChat) && selectedChat.length === 0) {
      console.log("[ChatHistory] Empty chat selection, clearing state");
      setShowReportScreen(false);
      setShowMeetingMinutesScreen(false);
      setMessages([]);
      setCurrentChatProtocol({});
      setCurrentChatHistoric([]);
      setCurrentChatKnowledge({});
      setCurrentChatType("");
      setCurrentChatSummary("");
    } else {
      console.log(
        "[ChatHistory] Setting messages from selected chat:",
        selectedChat.messages.length
      );
      setMessages(
        selectedChat.messages.map((message) => {
          return {
            id: message.id,
            role: message.role,
            question: message.role === "user" ? message.content : "",
            answer: message.role === "assistant" ? message.content : "",
            feedback: message.feedback,
          };
        })
      );
      setShowReportScreen(false);
      setShowMeetingMinutesScreen(false);
      setCurrentChatHistoric(selectedChat.historic || []);
      setCurrentChatKnowledge(selectedChat.knowledge || {});
      setCurrentChatProtocol(selectedChat.protocol || {});
      setCurrentChatType(selectedChat.type || "");
      setCurrentChatSummary(selectedChat.summary || "");
      if (selectedChat.type === "dagsol") {
        console.log("[ChatHistory] Setting DAGSol screen for selected chat");
        setShowDAGSolScreen(true);
      } else {
        setShowDAGSolScreen(false);
      }
    }
  };

  /**
   * Função para resetar a rolagem e a paginação do histórico.
   */
  const resetScrollAndPagination = () => {
    console.log("[ChatHistory] Resetting scroll and pagination");
    resetPagination();
    setScrollCheckpoint(0);
    setLastScrollPosition(0);
  };

  /**
   * Controle de skeletons
   */
  useEffect(() => {
    // Definir quantidade aleatória de skeletons entre 8 e 20
    setSkeletonCount(getRandomSkeletonCount());
    console.log("[ChatHistory] Initial data load started");
    getHistoric(1)
      .then((data) => {
        console.log("[ChatHistory] Initial historic data received:", data);
        console.log(
          "[ChatHistory] Raw history data:",
          JSON.stringify(data.history)
        );
        // Log each chat's protocol and latest message date for debugging
        if (data && data.history && data.history.length > 0) {
          console.log("[ChatHistory] Chat protocols and dates:");
          data.history.forEach((chat, index) => {
            const lastMessageDate =
              chat.messages && chat.messages.length > 0
                ? chat.messages[chat.messages.length - 1].date
                : "no messages";
            console.log(
              `  Chat ${index}: Protocol ${chat.protocol}, Last message: ${lastMessageDate}`
            );
          });
        }
        setChatData(data);
        resetScrollAndPagination();
      })
      .catch((error) => {
        console.error("[ChatHistory] Error loading initial history:", error);
      })
      .finally(() => {
        setLoading(false); // Desativar loading quando os dados forem carregados
      });
  }, []);

  /**
   * Efeito que busca o histórico de chat inicial ao montar o componente.
   */
  useEffect(() => {
    console.log("[ChatHistory] Loading initial chat history");
    getHistoric(1)
      .then((data) => {
        console.log(
          "[ChatHistory] Received initial chat history, chat count:",
          data.history?.length || 0
        );
        setChatData(data);
        resetScrollAndPagination();
      })
      .catch((error) => {
        console.error(
          "[ChatHistory] Error loading initial chat history:",
          error
        );
      });
  }, []);

  // Log received history prop
  useEffect(() => {
    if (history) {
      console.log("[ChatHistory] History prop updated:", history);
      console.log(
        "[ChatHistory] First few date groups:",
        history.slice(0, 3).map(([date, chats]) => {
          return {
            date,
            chatCount: chats.length,
            firstChatProtocol: chats[0]?.protocol,
            firstChatLastMessageDate:
              chats[0]?.messages?.[chats[0].messages.length - 1]?.date,
          };
        })
      );
    }
  }, [history]);

  return (
    <div
      ref={containerRef}
      className="relative flex-1 max-h-full overflow-x-hidden overflow-y-auto select-none transform-gpu"
    >
      {loading
        ? [...Array(skeletonCount)].map((_, index) => (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              key={index}
              className="z-50 flex p-2 px-0 text-sm font-normal leading-6 transition-colors duration-300 rounded-md pointer-events-none text-slate-400 group gap-x-3 animate-pulse"
            >
              <div
                className={`h-3.5 rounded bg-slate-200 dark:bg-slate-800 ${getRandomWidthClass()}`}
              ></div>
            </motion.div>
          ))
        : history.map(([dateGroup, chats], index) => {
            console.log(
              `[ChatHistory] Rendering date group: ${dateGroup}, chat count: ${chats.length}`
            );
            return (
              <React.Fragment key={dateGroup}>
                <div
                  className={`text-xs select-none ${
                    index === 0 ? "mt-0" : "mt-6"
                  } font-semibold leading-6 text-slate-200`}
                >
                  {dateGroup}
                </div>
                <ul className="mt-2 -mx-2 space-y-1">
                  {chats.map((chat) => {
                    console.log(
                      `[ChatHistory] Rendering chat: ${chat.protocol}, summary: ${chat.summary}, last message date: ${chat.messages[chat.messages.length - 1]?.date}`
                    );
                    return (
                      <div
                        key={chat.protocol}
                        onClick={() => {
                          handleConversationSelect(chat);
                          setCurrentSelection(chat.protocol);
                        }}
                        onMouseEnter={() => setEllipisisVisible(chat.protocol)}
                        onMouseLeave={() => setEllipisisVisible(null)}
                      >
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 0.5 }}
                          className="z-50 flex p-2 text-sm font-normal leading-6 transition-colors duration-300 rounded-md cursor-pointer text-slate-400 group gap-x-3 hover:text-white"
                        >
                          <span className="truncate">{chat.summary}</span>
                          <div className="absolute right-0 transition">
                            {ellipisisVisible === chat.protocol &&
                              chat.type !== "dagsol" && (
                                <Ellipsis
                                  isChatPinned={chat.pinned}
                                  chatUuid={chat.protocol}
                                  currentChatSummary={chat.summary}
                                />
                              )}
                          </div>
                        </motion.div>
                      </div>
                    );
                  })}
                </ul>
              </React.Fragment>
            );
          })}

      <div
        className="sticky -bottom-1 left-0 right-0 h-[100px]"
        style={{
          backgroundImage: "linear-gradient(to top, #111827, transparent)",
          pointerEvents: "none",
        }}
      ></div>
    </div>
  );
}
