export async function updateMeetingMinute(id, updatedMeetingMinute) {
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append(
    "Authorization",
    `Basic ${process.env.REACT_APP_HISTORIC_KEY}`
  );
  console.debug("Id recebido na função updateMeetingMinute", id);
  if (!id) {
    console.debug("Id is required");
    return;
  }
  const response = await fetch(
    `https://tadeo-services.apps.kloud.rnp.br/historic/meetingminute/`,
    {
      method: "PUT",
      headers: headers,
      body: JSON.stringify({
        id: id,
        meeting_minute: updatedMeetingMinute,
      }),
    }
  );

  if (!response.ok) {
    throw new Error("Failed to update meeting minute");
  }

  return await response.json();
}

export default updateMeetingMinute;
