import { motion, AnimatePresence } from "framer-motion";
import { useChatContext } from "../../../../contexts/ChatContext";

/**
 * Banner component for displaying warning and information messages to the user.
 * Ensures only one message is displayed at a time, based on a priority system.
 *
 * @returns {JSX.Element} The warning banner component
 */
export const InputWarningBanner = () => {
  const {
    showDAGSolScreen,
    showServerError,
    showEmptyMessageWarning,
    showWaitingMessageWarning,
    onlyRead,
    currentChatType,
    notAuthenticated,
  } = useChatContext();

  // Define the message to show based on priority
  const getMessage = () => {
    if (notAuthenticated) {
      return {
        text: "Solicite acesso para enviar mensagens.",
        className: "mb-2 text-red-400 text-center select-none text-[12px]",
        isError: true,
      };
    }

    if (showServerError) {
      return {
        text: "Ocorreu um erro no servidor. Tente novamente mais tarde.",
        className: "mb-2 text-red-400 text-center select-none text-[12px]",
        isError: true,
      };
    }

    if (showWaitingMessageWarning) {
      return {
        text: "Aguarde um instante, estamos trabalhando na resposta anterior.",
        className: "mb-2 text-slate-400 text-center select-none text-[12px]",
        isError: false,
      };
    }

    if (showEmptyMessageWarning) {
      return {
        text: "O campo de mensagem não pode ficar em branco. Digite algo antes de enviar.",
        className: "mb-2 text-slate-400 text-center select-none text-[12px]",
        isError: false,
      };
    }

    if (onlyRead || currentChatType === "Report") {
      return {
        text: "Chat disponível apenas para consulta.",
        className: "mb-2 text-slate-400 text-center select-none text-[12px]",
        isError: false,
      };
    }

    if (showDAGSolScreen) {
      return {
        text: "Esse chat pertence ao módulo DAGSol.",
        className:
          "mb-2 text-slate-400 text-center select-none text-[12px] flex items-center gap-x-2",
        isError: false,
        delay: 0.5,
      };
    }

    return null;
  };

  const activeMessage = getMessage();

  return (
    <AnimatePresence>
      {activeMessage && (
        <motion.div
          initial={{ opacity: 0, translateY: 10 }}
          animate={{ opacity: 1, translateY: 0 }}
          exit={{ opacity: 0, translateY: 10 }}
          transition={{
            duration: 0.2,
            ease: "easeOut",
            delay: activeMessage.delay || 0,
          }}
          className={activeMessage.className}
        >
          {activeMessage.text}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
