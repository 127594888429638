import React, { Fragment, useState, useRef, useEffect } from "react";
import { Transition } from "@headlessui/react";
import { sendReportData } from "../../../server/sendReportData";
import { getHistoric } from "../../../server/getHistoric";
import { useChatContext } from "../../../contexts/ChatContext";
import FeedbackDialog from "../../FeedbackDialog/FeedbackDialog";
import ReportTypes from "./ReportTypes/ReportTypes";
import { motion } from "framer-motion";

/**
 * Componente ReportWindow.
 *
 * @component
 *
 * @description
 * O componente `ReportWindow` permite que o usuário gere relatórios com base nas informações fornecidas, como o tipo de relatório, período de referência, e informações adicionais. Ele também fornece feedback em tempo real sobre o status do relatório (erro ou sucesso) e exibe um botão de download quando o relatório está pronto. O usuário pode escolher um período personalizado ou um período completo e gerar diferentes tipos de relatórios, como relatórios táticos.
 *
 * @returns {JSX.Element} O componente que renderiza a janela de geração de relatórios.
 *
 * @example
 * return <ReportWindow />;
 */
export default function ReportWindow() {
  const [reportType, setReportType] = useState("");
  const [periodType, setPeriodType] = useState("");
  const [showPeriodSection, setShowPeriodSection] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [taticalProjectName, setTaticalProjectName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [responsibleName, setResponsibleName] = useState("");
  const startInputRef = useRef(null);
  const endInputRef = useRef(null);
  const [show, setShow] = useState(false);
  const [showDataSendingSucess, setShowDataSendingSucess] = useState(false);
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [reportId, setReportId] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 10);
  }, []);

  const {
    setChatData,
    showReportError,
    setShowReportError,
    resetPagination,
    setCurrentChatHistoric,
    setCurrentChatKnowledge,
    setCurrentChatProtocol,
    setCurrentChatType,
    setShowReportScreen,
    setMessages,
    selectedTacticalReportOption,
  } = useChatContext();

  const handlePeriodChange = (newType) => {
    setShowPeriodSection(false);
    setTimeout(() => {
      setPeriodType(newType);
      setShowPeriodSection(true);
    }, 319);
  };

  async function generateReport(event) {
    event.preventDefault();

    let option;
    switch (reportType) {
      case "tactical-status-specific-project":
        option = "tactical status - specific project";
        break;
      case "tactical-status-specific-program":
        option = "tactical status - specific program";
        break;
      default:
        if (reportType.startsWith("tactical-status")) {
          option =
            "tactical status - " +
            selectedTacticalReportOption.replace(/-/g, " ");
        } else {
          option = reportType.replace(/-/g, " ");
        }
        break;
    }

    const reportData = {
      option,
      additionalInfo:
        reportType === "specific-management" && additionalInfo === ""
          ? "GAI"
          : additionalInfo,
      referencePeriod:
        periodType === "whole-period" ||
        reportType.startsWith("tactical-status")
          ? "ALL"
          : {
              start: startDate.replace(/\//g, "-"),
              end: endDate.replace(/\//g, "-"),
            },
    };

    try {
      sendReportData(
        reportData.option,
        reportData.additionalInfo,
        reportData.referencePeriod
      )
        .then((response) => {
          setShowDownloadButton(true);
          setReportId(response.report_identifier);
          getHistoric().then((data) => {
            setTimeout(() => {
              setShowReportScreen(false);
              setChatData(data);
              resetPagination();
              setMessages(
                data.history[0].messages.map((message) => {
                  return {
                    id: message.id,
                    role: message.role,
                    question: message.role === "user" ? message.content : "",
                    answer: message.role === "assistant" ? message.content : "",
                    feedback: message.feedback,
                  };
                })
              );
              setCurrentChatHistoric(data.history[0].historic);
              setCurrentChatKnowledge(data.history[0].knowledge);
              setCurrentChatProtocol(data.history[0].protocol);
              setCurrentChatType(data.history[0].type);
            }, 1000);
          });
        })
        .catch(() => {
          setShowReportError(true);
        });
    } catch (error) {
      setShowReportError(true);
      console.error("Failed to send report data:", error);
    }
  }

  const handleDateInput = (event, setDate, refNextInput) => {
    const { value } = event.target;
    const numbers = value.replace(/[^\d]/g, "");
    let formattedDate = "";

    if (numbers.length > 0) {
      formattedDate += numbers.substring(0, 2);
      if (numbers.length > 2) formattedDate += "/" + numbers.substring(2, 4);
      if (numbers.length > 4) formattedDate += "/" + numbers.substring(4, 8);
    }

    setDate(formattedDate);

    if (formattedDate.length === 10) {
      const [day, month, year] = formattedDate.split("/");
      const inputDate = new Date(year, month - 1, day);
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (inputDate > today) {
        alert("A data não pode ser futura.");
        setDate("");
      } else {
        if (refNextInput.current) {
          refNextInput.current.focus();
        }
      }
    }
  };

  useEffect(() => {
    setAdditionalInfo(taticalProjectName || projectName || responsibleName);
  }, [taticalProjectName, projectName, responsibleName]);

  const canSubmit = () => {
    if (!reportType || !additionalInfo) return false;

    if (reportType === "specific-project" && !additionalInfo) {
      return false;
    }

    if (periodType === "custom-period") {
      return startDate.length === 10 && endDate.length === 10;
    }

    return (
      periodType === "whole-period" || reportType.startsWith("tactical-status")
    );
  };

  useEffect(() => {
    if (showDownloadButton) {
      setShowDataSendingSucess(false);
    }
  }, [showDownloadButton, setShowDataSendingSucess]);

  return (
    <Transition
      show={show}
      as={Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <form
        className="mt-[65px]"
        onSubmit={(e) => {
          generateReport(e);
          setShowDataSendingSucess(true);
          setTaticalProjectName("");
          setAdditionalInfo("");
          setProjectName("");
          setResponsibleName("");
          setReportType("");
          setPeriodType("");
          setStartDate("");
          setEndDate("");
        }}
      >
        {showReportError && (
          <FeedbackDialog
            isError={true}
            title="Houve um erro."
            body="Houve um erro ao tentar gerar o relatório. Por favor, verifique as informações submetidas e tente novamente."
          />
        )}
        {showDataSendingSucess && (
          <FeedbackDialog
            isError={false}
            title="O relatório está sendo gerado."
            body="A confecção do relatório está em progresso. Aguarde um momento, por favor."
            onClose={() => setShowDataSendingSucess(false)}
            shouldShow={true}
          />
        )}

        {showDownloadButton && (
          <FeedbackDialog
            isError={false}
            title="Relatório pronto"
            body="O relatório solicitado está pronto para baixar no link abaixo."
            downloadLink={reportId}
            onClose={() => setShowDownloadButton(false)}
            shouldShow={true}
          />
        )}
        <div className="space-y-12">
          <div className="pb-12 border-b select-none border-slate-900/10 dark:border-slate-100/10">
            <h2 className="text-base font-semibold leading-7 text-slate-900 dark:text-slate-200">
              Novo relatório de projetos DEO
            </h2>
            <p className="mt-1 text-sm leading-6 text-slate-600 dark:text-slate-200">
              Por meio deste módulo é possível produzir relatórios atualizados
              acerca do desenvolvimento dos projetos conduzidos pela DEO.
            </p>
            <ReportTypes
              taticalProjectName={taticalProjectName}
              setTaticalProjectName={setTaticalProjectName}
              projectName={projectName}
              setProjectName={setProjectName}
              responsibleName={responsibleName}
              setResponsibleName={setResponsibleName}
              reportType={reportType}
              setReportType={setReportType}
            />
          </div>

          <div className="relative pb-12 border-b border-slate-900/10 dark:border-slate-100/10">
            {reportType.startsWith("tactical-status") && (
              <motion.span
                className="absolute top-[2px] select-none right-0 text-sm font-semibold text-indigo-300/80 dark:text-indigo-300/70"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                (não disponível em relatórios estratégicos)
              </motion.span>
            )}
            <div className="mt-10 space-y-10 select-none">
              <fieldset>
                <legend
                  className={`text-sm font-semibold leading-6 ${reportType.startsWith("tactical-status") ? "text-slate-400 dark:text-slate-600 pointer-events-none" : "text-slate-900 dark:text-slate-200"} transition-colors duration-300`}
                >
                  Período de referência{" "}
                </legend>
                <p
                  className={`mt-1 text-sm leading-6  ${reportType.startsWith("tactical-status") ? "text-slate-400 dark:text-slate-600 pointer-events-none" : "text-slate-600 dark:text-slate-200"} transition-colors duration-300`}
                >
                  Escolha o período de referência do novo relatório.
                </p>
                <div className="mt-6 space-y-6">
                  <div className="flex items-center gap-x-3">
                    <input
                      id="whole-period"
                      name="period"
                      value="whole-period"
                      checked={periodType === "whole-period"}
                      onChange={() => handlePeriodChange("whole-period")}
                      type="radio"
                      className="transition-colors duration-300 radio-button disabled:opacity-50"
                      disabled={reportType.startsWith("tactical-status")}
                    />
                    <label
                      htmlFor="whole-period"
                      className={`radio-label ${reportType.startsWith("tactical-status") ? "text-slate-400 dark:text-slate-600 pointer-events-none" : ""} transition-colors duration-300`}
                    >
                      Todo o histórico de vida do projeto
                    </label>
                  </div>
                  <div className="flex items-center gap-x-3">
                    <input
                      id="custom-period"
                      name="period"
                      value="custom-period"
                      checked={periodType === "custom-period"}
                      onChange={() => handlePeriodChange("custom-period")}
                      type="radio"
                      className="transition-colors duration-300 radio-button disabled:opacity-50"
                      disabled={reportType.startsWith("tactical-status")}
                    />
                    <label
                      htmlFor="custom-period"
                      className={`radio-label ${reportType.startsWith("tactical-status") ? "text-slate-400 dark:text-slate-600 pointer-events-none" : ""} transition-colors duration-300`}
                    >
                      Período personalizado
                    </label>
                  </div>
                </div>
                <Transition
                  show={showPeriodSection && periodType === "custom-period"}
                  as={Fragment}
                  enter="transition ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-300"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-4"
                >
                  <div className="mt-10 space-y-10">
                    <fieldset>
                      <legend
                        className={`text-sm font-semibold leading-6 ${reportType.startsWith("tactical-status") ? "text-slate-400 dark:text-slate-600 pointer-events-none" : "text-slate-900 dark:text-slate-200"}`}
                      >
                        Período personalizado
                      </legend>
                      <p
                        className={`"mt-1 text-sm leading-6 ${reportType.startsWith("tactical-status") ? "text-slate-400 dark:text-slate-600 pointer-events-none" : "text-slate-600 dark:text-slate-200"}`}
                      >
                        Escolha o período que o relatório deve se referir.
                      </p>
                      <div className="flex w-full mt-6 space-x-4">
                        <div
                          className={`rounded-md px-3 pb-1.5 pt-2.5 w-full shadow-sm ring-1 ring-inset ring-slate-300 dark:ring-slate-600 focus-within:ring-2 focus-within:ring-indigo-600 ${reportType.startsWith("tactical-status") ? "bg-slate-100/50 dark:bg-slate-800/80" : "bg-defaultWhite dark:bg-slate-900"}`}
                        >
                          <label
                            htmlFor="start-date"
                            className={`default-report-input-label ${reportType.startsWith("tactical-status") ? "text-slate-400 dark:text-slate-400 pointer-events-none" : "text-slate-600 dark:text-slate-200"}`}
                          >
                            Início do período
                          </label>
                          <input
                            type="text"
                            name="start-date"
                            id="start-date"
                            value={startDate}
                            onChange={(e) =>
                              handleDateInput(e, setStartDate, endInputRef)
                            }
                            className={`default-report-input disabled:bg-transparent dark:disabled:bg-transparent`}
                            placeholder="DD/MM/AAAA"
                            disabled={reportType.startsWith("tactical-status")}
                          />
                        </div>
                        <div
                          className={`rounded-md px-3 pb-1.5 pt-2.5 w-full shadow-sm ring-1 ring-inset ring-slate-300 dark:ring-slate-600 focus-within:ring-2 focus-within:ring-indigo-600 ${reportType.startsWith("tactical-status") ? "bg-slate-100/50 dark:bg-slate-800/80" : "bg-defaultWhite dark:bg-slate-900"}`}
                        >
                          <label
                            htmlFor="end-date"
                            className={`default-report-input-label ${reportType.startsWith("tactical-status") ? "text-slate-400 dark:text-slate-400 pointer-events-none" : "text-slate-600 dark:text-slate-200"}`}
                          >
                            Fim do período
                          </label>
                          <input
                            type="text"
                            name="end-date"
                            id="end-date"
                            ref={endInputRef}
                            value={endDate}
                            onChange={(e) =>
                              handleDateInput(e, setEndDate, startInputRef)
                            }
                            className={`default-report-input disabled:bg-transparent dark:disabled:bg-transparent`}
                            placeholder="DD/MM/AAAA"
                            disabled={reportType.startsWith("tactical-status")}
                          />
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </Transition>
              </fieldset>
            </div>
          </div>
        </div>

        <div className="mt-6 h-[100px] flex items-center justify-end gap-x-6 transition ease-in-out duration-300">
          <motion.div
            dangerouslySetInnerHTML={{
              __html: `
              <lord-icon
                src="https://cdn.lordicon.com/gkryirhd.json"
                trigger="loop"
                state="loop-snake-alt"
                colors="primary:#ffffff"
                style="width:30px;height:30px">
              </lord-icon>
            `,
            }}
            className="hidden dark:flex size-[30px] z-0"
            initial={{ opacity: 0 }}
            animate={{
              opacity: showDataSendingSucess ? 1 : 0,
              filter: showDataSendingSucess ? "blur(0px)" : "blur(20px)",
            }}
            transition={{ duration: 1 }}
          />
          <motion.div
            dangerouslySetInnerHTML={{
              __html: `
              <lord-icon
                src="https://cdn.lordicon.com/gkryirhd.json"
                trigger="loop"
                state="loop-snake-alt"
                colors="primary:#111827"
                style="width:30px;height:30px">
              </lord-icon>
            `,
            }}
            className="flex dark:hidden size-[30px] z-0"
            initial={{ opacity: 0 }}
            animate={{
              opacity: showDataSendingSucess ? 1 : 0,
              filter: showDataSendingSucess ? "blur(0px)" : "blur(20px)",
            }}
            transition={{ duration: 1 }}
          />
          <button
            type="submit"
            disabled={!canSubmit()}
            ref={startInputRef}
            className="btn-primary z-60"
          >
            Emitir relatório
          </button>
        </div>
      </form>
    </Transition>
  );
}
