import getUserInfo from "./user-auth/UserInfo.js";
import { debounce } from "lodash";
import getLangflowHistory from "./getLangflowHistory";

/**
 * Fetches chat history from the main API
 * @param {number} page - Page number for pagination
 * @returns {Promise<Object>} - Promise resolving to transformed chat data
 */
const fetchHistoric = (page = 1) => {
  const userInfo = getUserInfo();

  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Accept", "application/json");
  headers.append(
    "Authorization",
    `Basic ${process.env.REACT_APP_HISTORIC_KEY}`
  );

  const requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow",
  };

  return fetch(
    `https://tadeo-services.apps.kloud.rnp.br/historic/chat/?page=${page}&user__email=${userInfo.email}`,
    requestOptions
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json();
    })
    .then((data) => {
      const transformedData = {
        history: data.results.map((item) => ({
          protocol: item.protocol,
          summary: item.summary,
          pinned: item.pinned,
          historic: item.historic,
          knowledge: item.knowledge,
          type: item.type,
          messages: item.message_chat.map((message) => ({
            id: message.message_id,
            date: message.date,
            role: message.role,
            content: message.content,
            feedback: message.feedback,
          })),
        })),
      };
      return transformedData;
    })
    .catch((error) => {
      console.error("Error:", error);
      throw error;
    });
};

const debouncedFetchHistoric = debounce((resolve, reject, page) => {
  fetchHistoric(page)
    .then(async (historicData) => {
      try {
        // Check if user is whitelisted for DAGSol access
        const userInfo = getUserInfo();
        if (!userInfo) {
          resolve(historicData);
          return;
        }

        const userEmail = userInfo.email;
        const whitelistEnv = process.env.REACT_APP_DAGSOL_WHITELIST || "";
        const meetingMinutesWhitelist = whitelistEnv
          .split(",")
          .map((email) => email.trim().toLowerCase());

        // Check if user is whitelisted
        const isUserWhitelisted = meetingMinutesWhitelist.includes(
          userEmail.toLowerCase()
        );

        // If user is not whitelisted, return only historic data
        if (!isUserWhitelisted) {
          resolve(historicData);
          return;
        }

        // If user is whitelisted, fetch and merge Langflow data

        const langflowChats = await getLangflowHistory(userEmail);

        if (langflowChats && langflowChats.length > 0) {
          // Create a map of existing chats by protocol for easy lookup
          const existingChatsMap = new Map();
          historicData.history.forEach((chat) => {
            existingChatsMap.set(chat.protocol, chat);
          });

          // Add new chats that don't already exist
          langflowChats.forEach((newChat) => {
            if (!existingChatsMap.has(newChat.protocol)) {
              existingChatsMap.set(newChat.protocol, newChat);
            }
          });

          // Convert back to array and prioritize Langflow chats at the beginning
          const mergedHistory = Array.from(existingChatsMap.values());

          // Sort to show DAGSol chats first, then regular chats
          mergedHistory.sort((a, b) => {
            if (a.type === "dagsol" && b.type !== "dagsol") return -1;
            if (a.type !== "dagsol" && b.type === "dagsol") return 1;
            return 0;
          });

          // Return merged data
          resolve({
            ...historicData,
            history: mergedHistory,
          });
          console.log(
            `Added ${langflowChats.length} Langflow chats to history`
          );
        } else {
          resolve(historicData);
        }
      } catch (error) {
        console.error("Error merging DAGSol data:", error);
        resolve(historicData); // Still return the historic data on error
      }
    })
    .catch(reject);
}, 300);

/**
 * Fetches chat history and combines with Langflow (DAGSol) data when appropriate
 * @param {number} page - Page number for pagination (default: 1)
 * @returns {Promise<Object>} - Promise resolving to combined chat data
 */
export const getHistoric = (page = 1) => {
  return new Promise((resolve, reject) => {
    debouncedFetchHistoric(resolve, reject, page);
  });
};
