import { UploadTranscriptionFile } from "./UploadTranscriptionFile/UploadTranscriptionFile.jsx";
import { AnimatePresence } from "framer-motion";
import MeetingMinuteDetails from "./MeetingMinuteDetails/MeetingMinuteDetails.jsx";
import { useEffect, useState } from "react";
import { useMeetingMinute } from "../../../contexts/MeetingMinuteContext.jsx";

export function MeetingMinutes() {
  const { meetingData, step, setStep } = useMeetingMinute();

  const handleOnFileSelect = () => setStep(2);

  const handleUploadSuccess = () => {
    console.debug("");
  };

  useEffect(() => {
    if (meetingData) {
      setStep(2);
    } else {
      setStep(1);
    }
  }, [meetingData]);

  return (
    <AnimatePresence>
      {getCurrentStep(step, handleUploadSuccess, handleOnFileSelect)}
    </AnimatePresence>
  );
}

function getCurrentStep(step, onUploadSuccess, onFileSelect) {
  switch (step) {
    case 1:
      return (
        <UploadTranscriptionFile
          onUploadSuccess={onUploadSuccess}
          onFileSelect={onFileSelect}
        />
      );
    default:
      return <MeetingMinuteDetails />;
  }
}
