import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import InitialMessage from "../config/chatMessages.json";
import getProjectNames from "../server/getProjectNames";
import getResponsibles from "../server/getResponsibles";
import getProgramNames from "../server/getProgramNames";
import getUserInfo from "../server/user-auth/UserInfo";
import { auth } from "../database/FirebaseConnection";
import { onAuthStateChanged } from "firebase/auth";

/**
 * Criação do contexto de chat.
 * O contexto permite compartilhar estados e funções relacionados ao chat em toda a aplicação.
 */
const ChatContext = createContext();

/**
 * Hook personalizado para acessar o contexto do chat.
 * @returns {object} O contexto do chat.
 */
export const useChatContext = () => useContext(ChatContext);

/**
 * Provedor de contexto de chat.
 * Fornece valores e estados relacionados à funcionalidade de chat para os componentes filhos.
 *
 * @param {object} props - As propriedades do componente.
 * @param {ReactNode} props.children - Os componentes filhos que terão acesso ao contexto.
 *
 * @returns {JSX.Element} O provedor de contexto de chat.
 */
export const ChatProvider = ({ children }) => {
  const [chatData, setChatData] = useState({ history: [] });
  const [messages, setMessages] = useState([]);
  const [currentChatHistoric, setCurrentChatHistoric] = useState([]);
  const [currentChatKnowledge, setCurrentChatKnowledge] = useState({});
  const [currentChatProtocol, setCurrentChatProtocol] = useState({});
  const [currentChatSummary, setCurrentChatSummary] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [showServerError, setShowServerError] = useState(false);
  const [showReportScreen, setShowReportScreen] = useState(false);
  const [reportConversation, setReportConversation] = useState(InitialMessage);
  const [isPoll, setIsPoll] = useState();
  const [resetConversation, setResetConversation] = useState(false);
  const [onlyRead, setOnlyRead] = useState(false);
  const [notAuthenticated, setNotAuthenticated] = useState(false);
  const [deletedChatId, setDeletedChatId] = useState();
  const [renamedChatId, setRenamedChatId] = useState();
  const [renamedChatSummary, setRenamedChatSummary] = useState();
  const [mobileNavBarOpen, setMobileNavBarOpen] = useState(false);
  const [currentSelection, setCurrentSelection] = useState("Nova conversa");
  const [showReportError, setShowReportError] = useState(false);
  const [currentChatType, setCurrentChatType] = useState("");
  const [changelogOpen, setChangelogOpen] = useState(false);
  const [reportForm, setReportForm] = useState(false);
  const [showMobileMessagesOptions, setShowMobileMessagesOptions] =
    useState(false);
  const [mobileOptionsCount, setMobileOptionsCount] = useState(0);
  const [currentHistoryPagination, setCurrentHistoryPagination] = useState(1);
  const [isSearchDialogOpen, setIsSearchDialogOpen] = useState(false);
  const [selectedTacticalReportOption, setSelectedTacticalReportOption] =
    useState("specific project");
  const [showEmptyMessageWarning, setShowEmptyMessageWarning] = useState(false);
  const [showWaitingMessageWarning, setShowWaitingMessageWarning] =
    useState(false);

  const [projectNames, setProjectNames] = useState([]);
  const [programNames, setProgramNames] = useState([]);
  const [responsibles, setResponsibles] = useState([]);
  const [chatBeingUpdated, setChatBeingUpdated] = useState(null);
  const [showMeetingMinutesScreen, setShowMeetingMinutesScreen] =
    useState(false);
  const [showDAGSolScreen, setShowDAGSolScreen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isUserWhitelisted, setIsUserWhitelisted] = useState(false);

  // Use an auth state listener to properly update user data when auth state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        const userInfo = getUserInfo();
        if (userInfo) {
          setUserData(userInfo);

          const userEmail = userInfo.email;
          const whitelistEnv = process.env.REACT_APP_DAGSOL_WHITELIST || "";
          const meetingMinutesWhitelist = whitelistEnv
            .split(",")
            .map((email) => email.trim().toLowerCase());

          // Check if user is whitelisted
          const isWhitelisted = meetingMinutesWhitelist.includes(
            userEmail.toLowerCase()
          );
          setIsUserWhitelisted(isWhitelisted);
          console.log(
            "User authentication updated:",
            userInfo,
            "Whitelisted:",
            isWhitelisted
          );
        }
      } else {
        setUserData(null);
        setIsUserWhitelisted(false);
        console.log("User not authenticated");
      }
    });

    // Clean up subscription on unmount
    return () => unsubscribe();
  }, []);

  // Add a useEffect to log userData and isUserWhitelisted changes
  useEffect(() => {
    console.log("Context authentication state updated:", {
      userData,
      isUserWhitelisted,
      authCurrentUser: auth.currentUser,
    });
  }, [userData, isUserWhitelisted]);

  /**
   * Função para resetar a paginação do histórico de chats.
   */
  const resetPagination = () => {
    setCurrentHistoryPagination(1);
  };

  /**
   * useEffect para inicializar os dados ao carregar o componente.
   * Carrega os nomes dos projetos, programas e responsáveis.
   */
  useEffect(() => {
    async function initializeData() {
      try {
        const projects = await getProjectNames();
        setProjectNames(projects);

        const programs = await getProgramNames();
        setProgramNames(programs);

        const responsiblesList = await getResponsibles();
        setResponsibles(responsiblesList);
      } catch (error) {
        console.error("Error initializing data:", error);
      }
    }

    initializeData();
  }, []);

  const contextValue = useMemo(
    () => ({
      chatData,
      setChatData,
      messages,
      setMessages,
      currentChatHistoric,
      setCurrentChatHistoric,
      currentChatKnowledge,
      setCurrentChatKnowledge,
      currentChatProtocol,
      setCurrentChatProtocol,
      currentChatSummary,
      setCurrentChatSummary,
      isTyping,
      setIsTyping,
      showServerError,
      setShowServerError,
      showReportScreen,
      setShowReportScreen,
      reportConversation,
      setReportConversation,
      isPoll,
      setIsPoll,
      resetConversation,
      setResetConversation,
      onlyRead,
      setOnlyRead,
      notAuthenticated,
      setNotAuthenticated,
      deletedChatId,
      setDeletedChatId,
      mobileNavBarOpen,
      setMobileNavBarOpen,
      currentSelection,
      setCurrentSelection,
      renamedChatId,
      setRenamedChatId,
      renamedChatSummary,
      setRenamedChatSummary,
      showReportError,
      setShowReportError,
      currentChatType,
      setCurrentChatType,
      changelogOpen,
      setChangelogOpen,
      reportForm,
      setReportForm,
      showMobileMessagesOptions,
      setShowMobileMessagesOptions,
      mobileOptionsCount,
      setMobileOptionsCount,
      currentHistoryPagination,
      setCurrentHistoryPagination,
      resetPagination,
      isSearchDialogOpen,
      setIsSearchDialogOpen,
      selectedTacticalReportOption,
      setSelectedTacticalReportOption,
      chatBeingUpdated,
      setChatBeingUpdated,
      projectNames,
      programNames,
      responsibles,
      showMeetingMinutesScreen,
      setShowMeetingMinutesScreen,
      showDAGSolScreen,
      setShowDAGSolScreen,
      showEmptyMessageWarning,
      setShowEmptyMessageWarning,
      showWaitingMessageWarning,
      setShowWaitingMessageWarning,
      isUserWhitelisted,
      userData,
    }),
    [
      chatData,
      messages,
      currentChatHistoric,
      currentChatKnowledge,
      currentChatProtocol,
      isTyping,
      showServerError,
      showReportScreen,
      reportConversation,
      isPoll,
      resetConversation,
      onlyRead,
      notAuthenticated,
      deletedChatId,
      mobileNavBarOpen,
      currentSelection,
      renamedChatId,
      renamedChatSummary,
      showReportError,
      currentChatType,
      changelogOpen,
      reportForm,
      showMobileMessagesOptions,
      mobileOptionsCount,
      currentHistoryPagination,
      isSearchDialogOpen,
      selectedTacticalReportOption,
      chatBeingUpdated,
      projectNames,
      programNames,
      responsibles,
      showMeetingMinutesScreen,
      showDAGSolScreen,
      showEmptyMessageWarning,
      showWaitingMessageWarning,
      isUserWhitelisted,
      userData,
    ]
  );

  return (
    <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>
  );
};

ChatProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
