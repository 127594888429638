import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  forwardRef,
} from "react";
import { motion, AnimatePresence } from "framer-motion";
import { sendMessage } from "../../../server/sendMessage";
import { sendDAGSolMessage } from "../../../server/sendDAGSolMessage";
import { useChatContext } from "../../../contexts/ChatContext";
import { PromptModels } from "../PromptModels/PromptModels";
import { InputWarningBanner } from "./InputWarningBanner/InputWarningBanner";

/**
 * Componente TextInput.
 *
 * @component
 *
 * @description
 * O `TextInput` é um componente que permite ao usuário enviar mensagens em um chat. Ele lida com o estado de digitação, valida a mensagem, envia-a ao servidor e recebe a resposta. O componente também oferece feedback visual, como alertas de mensagem vazia ou em espera, e exibe sugestões de prompts quando o histórico de mensagens está vazio. Adicionalmente, lida com diferentes estados, como erro de servidor, autenticação e modo de leitura.
 *
 * @param {Object} props - Propriedades do componente.
 * @param {Function} props.onSendMessage - Função chamada quando o usuário envia uma mensagem.
 * @param {Function} props.onReceiveResponse - Função chamada quando a resposta da mensagem é recebida.
 *
 * @returns {JSX.Element} Um componente de input de texto para chat com funcionalidade de envio de mensagens e feedback visual.
 *
 * @example
 * const handleSendMessage = (message) => console.log("Mensagem enviada:", message);
 * const handleReceiveResponse = (response) => console.log("Resposta recebida:", response);
 * return <TextInput onSendMessage={handleSendMessage} onReceiveResponse={handleReceiveResponse} />;
 */
const TextInput = forwardRef(
  ({ onSendMessage, onReceiveResponse }, forwardedRef) => {
    const {
      chatData,
      setChatData,
      messages,
      currentChatProtocol,
      currentChatHistoric,
      currentChatKnowledge,
      setCurrentChatHistoric,
      setCurrentChatKnowledge,
      setCurrentChatProtocol,
      isTyping,
      setIsTyping,
      showServerError,
      setShowServerError,
      showReportScreen,
      reportConversation,
      setReportConversation,
      isPoll,
      onlyRead,
      notAuthenticated,
      setNotAuthenticated,
      currentChatType,
      showDAGSolScreen,
      setShowEmptyMessageWarning,
      setShowWaitingMessageWarning,
    } = useChatContext();

    const internalRef = useRef(null);
    const textareaRef = forwardedRef || internalRef;
    const [message, setMessage] = useState("");
    const [duringConversationSuggestions, setDuringConversationSuggestions] =
      useState([]);

    const autoResize = useCallback(() => {
      const textarea = textareaRef.current;
      if (!textarea) return;

      const maxHeight = 200;

      textarea.style.height = "40px";

      const newHeight = Math.min(textarea.scrollHeight, maxHeight);

      textarea.style.height = `${newHeight}px`;
    }, [textareaRef]);

    const trimmedMessage = message.trim();

    const chatHistory = chatData.history ? chatData.history : [];

    // Function to format date in the expected format: YYYY-MM-DD HH:MM:SS
    function formatDateForChat() {
      const now = new Date();

      // Format year, month, and day
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");

      // Format hours, minutes, and seconds
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const seconds = String(now.getSeconds()).padStart(2, "0");

      const formatted = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      console.log("[DateFormat] Formatted date:", formatted);
      return formatted;
    }

    const sendMessageIfApplicable = useCallback(
      (prompt) => {
        const messageToSend = prompt || trimmedMessage;
        setDuringConversationSuggestions([]);
        console.log("[SendMessage] Starting to send message:", messageToSend);
        console.log(
          "[SendMessage] Current chat history:",
          JSON.stringify(chatHistory)
        );

        if (messageToSend === "") {
          setShowEmptyMessageWarning(true);
        } else if (isTyping) {
          setShowWaitingMessageWarning(true);
        } else {
          setShowEmptyMessageWarning(false);
          setTimeout(() => {
            setIsTyping(true);
          }, 450);
          onSendMessage(messageToSend);
          if (!showReportScreen) {
            const currentProtocol = currentChatProtocol;
            console.log("[SendMessage] Current protocol:", currentProtocol);

            // Use DAGSol endpoint if showDAGSolScreen is true or if currentChatType is "dagsol"
            if (showDAGSolScreen || currentChatType === "dagsol") {
              console.log("[SendMessage] Using DAGSol flow");
              sendDAGSolMessage(messageToSend, currentProtocol)
                .then((response) => {
                  console.log("[DAGSol] Response received:", response);
                  onReceiveResponse(response.answer);
                  const protocolBase = response.protocol;
                  const chatIndex = chatHistory.findIndex(
                    (chat) => chat.protocol === protocolBase
                  );
                  console.log("[DAGSol] Chat index in history:", chatIndex);

                  // Create a modified copy of chat history where all existing chats have incremented sortIndex
                  const updatedChatHistory = chatHistory.map((chat) => ({
                    ...chat,
                    sortIndex: (chat.sortIndex || 0) + 1,
                  }));
                  console.log(
                    "[DAGSol] Updated chat history with incremented sortIndex:",
                    JSON.stringify(updatedChatHistory)
                  );

                  if (chatIndex >= 0) {
                    console.log(
                      "[DAGSol] Updating existing chat at index:",
                      chatIndex
                    );
                    updateExistingChat(
                      protocolBase,
                      response,
                      updatedChatHistory,
                      messageToSend
                    );
                    // Sort history by sortIndex to ensure proper order
                    const sortedHistory = [...updatedChatHistory].sort(
                      (a, b) => (a.sortIndex || 0) - (b.sortIndex || 0)
                    );
                    console.log(
                      "[DAGSol] Sorted history (updating):",
                      JSON.stringify(sortedHistory)
                    );
                    console.log(
                      "[DAGSol] First chat in sorted history:",
                      sortedHistory[0]?.protocol
                    );
                    setChatData({ ...chatData, history: sortedHistory });
                  } else {
                    console.log("[DAGSol] Creating new chat");
                    const newChat = createNewChat(response, messageToSend);
                    console.log(
                      "[DAGSol] New chat created:",
                      JSON.stringify(newChat)
                    );
                    // Add new chat and sort by sortIndex
                    const combinedHistory = [newChat, ...updatedChatHistory];
                    console.log(
                      "[DAGSol] Combined history:",
                      JSON.stringify(combinedHistory.map((c) => c.protocol))
                    );
                    const sortedHistory = combinedHistory.sort(
                      (a, b) => (a.sortIndex || 0) - (b.sortIndex || 0)
                    );
                    console.log(
                      "[DAGSol] Sorted history (new chat):",
                      JSON.stringify(sortedHistory.map((c) => c.protocol))
                    );
                    console.log(
                      "[DAGSol] First chat in sorted history:",
                      sortedHistory[0]?.protocol
                    );
                    setChatData({
                      ...chatData,
                      history: sortedHistory,
                    });
                  }

                  setCurrentChatHistoric(
                    response.historic || currentChatHistoric
                  );
                  setCurrentChatKnowledge(
                    response.knowledge || currentChatKnowledge
                  );
                  setCurrentChatProtocol(response.protocol);
                  setIsTyping(false);
                })
                .catch((error) => {
                  console.error("[DAGSol] Error:", error);
                  if (error.message === "User is not authenticated") {
                    setNotAuthenticated(true);
                  } else if (
                    error.message.includes("HTTP error") ||
                    error.message === "Network Error"
                  ) {
                    console.error("Error sending message to DAGSol:", error);
                    setShowServerError(true);
                  } else {
                    // Handle other errors
                    console.error(
                      "Unexpected error in DAGSol communication:",
                      error
                    );
                    setShowServerError(true);
                  }
                  setTimeout(() => {
                    setIsTyping(false);
                  }, 450);
                });
            } else {
              // Original sendMessage handling
              console.log("[SendMessage] Using regular flow");
              sendMessage(
                messageToSend,
                currentProtocol,
                currentChatHistoric,
                currentChatKnowledge
              )
                .then((response) => {
                  console.log("[Regular] Response received:", response);
                  onReceiveResponse(response.answer);
                  const protocolBase = currentChatProtocol;
                  const chatIndex = chatHistory.findIndex((chat) =>
                    chat.protocol.startsWith(protocolBase)
                  );
                  console.log("[Regular] Chat index in history:", chatIndex);

                  // Create a modified copy of chat history where all existing chats have incremented sortIndex
                  const updatedChatHistory = chatHistory.map((chat) => ({
                    ...chat,
                    sortIndex: (chat.sortIndex || 0) + 1,
                  }));
                  console.log(
                    "[Regular] Updated chat history with incremented sortIndex:",
                    JSON.stringify(updatedChatHistory.map((c) => c.protocol))
                  );

                  if (response.suggestions && response.suggestions.length > 0) {
                    setDuringConversationSuggestions(response.suggestions);
                  }

                  if (chatIndex >= 0) {
                    console.log(
                      "[Regular] Updating existing chat at index:",
                      chatIndex
                    );
                    const existingChatSummary = chatHistory[chatIndex].summary;
                    const newSummary = response.summary;

                    if (existingChatSummary !== newSummary) {
                      updatedChatHistory[chatIndex].summary = newSummary;
                    }
                    updateExistingChat(
                      currentProtocol,
                      response,
                      updatedChatHistory,
                      messageToSend
                    );
                    // Sort history by sortIndex to ensure proper order
                    const sortedHistory = [...updatedChatHistory].sort(
                      (a, b) => (a.sortIndex || 0) - (b.sortIndex || 0)
                    );
                    console.log(
                      "[Regular] Sorted history (updating):",
                      JSON.stringify(sortedHistory.map((c) => c.protocol))
                    );
                    console.log(
                      "[Regular] First chat in sorted history:",
                      sortedHistory[0]?.protocol
                    );
                    console.log(
                      "[Regular] Chat dates in sorted history:",
                      JSON.stringify(
                        sortedHistory.map(
                          (c) => c.messages[c.messages.length - 1].date
                        )
                      )
                    );
                    setChatData({ ...chatData, history: sortedHistory });
                  } else {
                    console.log("[Regular] Creating new chat");
                    const newChat = createNewChat(response, messageToSend);
                    console.log(
                      "[Regular] New chat created:",
                      JSON.stringify(newChat)
                    );
                    // Add new chat and sort by sortIndex
                    const combinedHistory = [newChat, ...updatedChatHistory];
                    console.log(
                      "[Regular] Combined history:",
                      JSON.stringify(combinedHistory.map((c) => c.protocol))
                    );
                    const sortedHistory = combinedHistory.sort(
                      (a, b) => (a.sortIndex || 0) - (b.sortIndex || 0)
                    );
                    console.log(
                      "[Regular] Sorted history (new chat):",
                      JSON.stringify(sortedHistory.map((c) => c.protocol))
                    );
                    console.log(
                      "[Regular] First chat in sorted history:",
                      sortedHistory[0]?.protocol
                    );
                    console.log(
                      "[Regular] Chat dates in sorted history:",
                      JSON.stringify(
                        sortedHistory.map(
                          (c) => c.messages[c.messages.length - 1].date
                        )
                      )
                    );
                    setChatData({
                      ...chatData,
                      history: sortedHistory,
                    });
                  }

                  setCurrentChatHistoric(
                    response.historic || currentChatHistoric
                  );
                  setCurrentChatKnowledge(
                    response.knowledge || currentChatKnowledge
                  );
                  setCurrentChatProtocol(
                    response.protocol || currentChatProtocol
                  );
                  setIsTyping(false);
                })
                .catch((error) => {
                  console.error("[Regular] Error:", error);
                  if (error.message === "User is not authenticated") {
                    setNotAuthenticated(true);
                  } else if (error.message === "Network Error") {
                    console.error("Error sending message:", error);
                    setShowServerError(true);
                  }
                  setTimeout(() => {
                    setIsTyping(false);
                  }, 450);
                });
            }
          } else {
            const userResponse = {
              role: "user",
              question: messageToSend,
              answer: "",
            };
            setReportConversation([...reportConversation, userResponse]);
          }

          setMessage("");
          autoResize();
        }
      },
      [
        trimmedMessage,
        isTyping,
        showReportScreen,
        showDAGSolScreen,
        currentChatProtocol,
        currentChatHistoric,
        currentChatKnowledge,
        chatHistory,
        setChatData,
        setCurrentChatHistoric,
        setCurrentChatKnowledge,
        setCurrentChatProtocol,
        setIsTyping,
        setNotAuthenticated,
        setShowServerError,
        onSendMessage,
        onReceiveResponse,
        autoResize,
        currentChatType,
      ]
    );

    const handleSelectPrompt = useCallback(
      (prompt) => {
        sendMessageIfApplicable(prompt);
      },
      [sendMessageIfApplicable]
    );

    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = "inherit";
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    }, [message, textareaRef]);

    useEffect(() => {
      if (!isTyping) {
        setShowWaitingMessageWarning(false);
      }
    }, [isTyping, isPoll]);

    useEffect(() => {
      if (!showReportScreen && textareaRef.current) {
        textareaRef.current.focus();
      }
    }, [showReportScreen, textareaRef]);

    const handleKeyDown = useCallback(
      (e) => {
        if (e.key === "Enter" && !e.shiftKey) {
          e.preventDefault();
          sendMessageIfApplicable();
        }
      },
      [sendMessageIfApplicable]
    );

    const handleChange = useCallback((e) => {
      setMessage(e.target.value);
    }, []);

    const isDisabled = useCallback(() => {
      return (
        notAuthenticated ||
        showServerError ||
        (isPoll &&
          (!currentChatProtocol ||
            Object.keys(currentChatProtocol).length === 0)) ||
        onlyRead ||
        currentChatType === "Report"
      );
    }, [
      notAuthenticated,
      showServerError,
      isPoll,
      currentChatProtocol,
      onlyRead,
      currentChatType,
    ]);

    function updateExistingChat(protocol, response, chatHistory, message) {
      console.log(
        "[UpdateExistingChat] Starting update for protocol:",
        protocol
      );
      console.log(
        "[UpdateExistingChat] Chat history before update:",
        JSON.stringify(chatHistory.map((c) => c.protocol))
      );

      const chatIndex = chatHistory.findIndex(
        (chat) => chat.protocol === protocol
      );
      console.log("[UpdateExistingChat] Found chat at index:", chatIndex);

      chatHistory[chatIndex].historic = response.historic;
      chatHistory[chatIndex].knowledge = response.knowledge;
      // Set this chat as the most recent one by assigning lowest sortIndex
      chatHistory[chatIndex].sortIndex = -1;
      console.log(
        "[UpdateExistingChat] Set sortIndex to -1 for chat at index:",
        chatIndex
      );

      if (chatIndex >= 0) {
        const newMessageId = chatHistory[chatIndex].messages.length;
        // Use the correct date format
        const formattedDate = formatDateForChat();
        console.log(
          "[UpdateExistingChat] Formatted date for new messages:",
          formattedDate
        );

        chatHistory[chatIndex].messages.push(
          {
            id: `${response.protocol}0000${newMessageId}`,
            date: formattedDate,
            role: "user",
            content: message,
            feedback: null,
          },
          {
            id: `${response.protocol}0000${newMessageId + 1}`,
            date: formattedDate,
            role: "assistant",
            content: response.answer,
            feedback: null,
          }
        );
        console.log(
          "[UpdateExistingChat] Messages added to chat, total now:",
          chatHistory[chatIndex].messages.length
        );
      }

      console.log(
        "[UpdateExistingChat] Chat history after update:",
        JSON.stringify(
          chatHistory.map((c) => ({
            protocol: c.protocol,
            sortIndex: c.sortIndex,
            lastMessage: c.messages[c.messages.length - 1].date,
          }))
        )
      );
    }

    function createNewChat(response, message) {
      console.log(
        "[CreateNewChat] Starting creation of new chat for protocol:",
        response.protocol
      );

      // Use the correct date format
      const formattedDate = formatDateForChat();
      console.log(
        "[CreateNewChat] Formatted date for new chat:",
        formattedDate
      );

      const newChat = {
        protocol: response.protocol,
        summary: response.summary || "Sumário do chat",
        pinned: false,
        historic: response.historic,
        knowledge: response.knowledge || "",
        // Add sortIndex to force this chat to the top
        sortIndex: -1,
        // Add lastUpdated with the correct format for sorting
        lastUpdated: formattedDate,
        messages: [
          {
            id: `${response.protocol}00000`,
            date: formattedDate,
            role: "user",
            content: message,
            feedback: null,
          },
          {
            id: `${response.protocol}00001`,
            date: formattedDate,
            role: "assistant",
            content: response.answer,
            feedback: null,
          },
        ],
      };

      console.log(
        "[CreateNewChat] Created new chat:",
        JSON.stringify({
          protocol: newChat.protocol,
          sortIndex: newChat.sortIndex,
          lastUpdated: newChat.lastUpdated,
          messageDate: newChat.messages[0].date,
        })
      );

      return newChat;
    }

    return (
      <motion.div
        className="flex flex-col items-center justify-center w-full"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        <InputWarningBanner />
        <AnimatePresence>
          {(messages.length === 0 ||
            (duringConversationSuggestions &&
              duringConversationSuggestions.length > 0)) &&
            !showDAGSolScreen && (
              <PromptModels
                onSelect={handleSelectPrompt}
                suggestions={duringConversationSuggestions}
              />
            )}
        </AnimatePresence>
        <div
          className={`relative flex items-center justify-center smallMobile:w-full horizontalTablet:w-[800px] p-2 border rounded-lg text-black overflow-hidden max-h-[300px] textarea-custom-focus transition-colors duration-300 dark:border-slate-600 ${
            isDisabled()
              ? "bg-[#f9fafb] dark:bg-[#1d2432]"
              : "bg-defaultWhite dark:bg-[#0b1120]"
          }`}
        >
          <textarea
            ref={textareaRef}
            value={message}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onInput={autoResize}
            style={{ maxHeight: "200px" }}
            rows="1"
            className={`w-full resize-none focus:outline-none border-none dark:text-slate-100 focus:ring-0 focus:border-none transition-colors duration-300 ${
              isDisabled()
                ? "bg-[#f9fafb] dark:bg-[#1d2432]"
                : "bg-defaultWhite dark:bg-[#0b1120]"
            }`}
            placeholder={showServerError ? "" : "Digite uma mensagem"}
            disabled={isDisabled()}
          />
          <div className="flex items-center justify-center ">
            <button
              aria-label="Enviar"
              className="p-2 ml-2 transition-colors duration-300 bg-slate-100 rounded-lg hover:bg-slate-300 dark:bg-slate-800 hover:dark:bg-slate-700 focus:outline-none disabled:pointer-events-none disabled:opacity-70 disabled:dark:bg-[#1d2432] disabled:bg-[#f9fafb]"
              onClick={() => {
                sendMessageIfApplicable();
              }}
              disabled={isDisabled()}
            >
              <div className="block dark:hidden">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="#374151"
                  className="size-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 17a.75.75 0 0 1-.75-.75V5.612L5.29 9.77a.75.75 0 0 1-1.08-1.04l5.25-5.5a.75.75 0 0 1 1.08 0l5.25 5.5a.75.75 0 1 1-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0 1 10 17Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="hidden dark:block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="#ffffff"
                  className="size-5"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 17a.75.75 0 0 1-.75-.75V5.612L5.29 9.77a.75.75 0 0 1-1.08-1.04l5.25-5.5a.75.75 0 0 1 1.08 0l5.25 5.5a.75.75 0 1 1-1.08 1.04l-3.96-4.158V16.25A.75.75 0 0 1 10 17Z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </button>
          </div>
        </div>
        <div className="mt-2 text-slate-400 text-center text-[12px] select-none">
          O Tadeo pode cometer erros. Considere verificar informações
          importantes.
        </div>
      </motion.div>
    );
  }
);

export default TextInput;
