import getUserInfo from "./user-auth/UserInfo";
import { v4 as uuidv4 } from "uuid";

/**
 * Sends a message to the DAGSol document analysis service
 * @param {string} message - The user's message
 * @param {string|object} sessionId - The existing session ID (chat protocol) or empty for new sessions
 * @returns {Promise} - Promise resolving to the DAGSol response
 */
export const sendDAGSolMessage = async (message, sessionId = "") => {
  return new Promise((resolve, reject) => {
    try {
      // Get user information
      const userInfo = getUserInfo();

      if (!userInfo) {
        reject(new Error("User is not authenticated"));
        return;
      }

      const { email } = userInfo;

      // Ensure we have a proper session ID string
      let chatSessionId;

      // Check if sessionId is empty, null, or an empty object
      if (
        !sessionId ||
        sessionId === "" ||
        (typeof sessionId === "object" && Object.keys(sessionId).length === 0)
      ) {
        // Generate a new UUID if no valid sessionId is provided
        chatSessionId = uuidv4();
      } else if (typeof sessionId === "object") {
        // If it's a non-empty object, stringify it (should not normally happen)
        chatSessionId = JSON.stringify(sessionId);
      } else {
        // Use the existing string sessionId
        chatSessionId = sessionId;
      }

      // Create headers
      const headers = new Headers();
      const apiKey = process.env.REACT_APP_DAGSOL_DOCS_API_KEY;
      if (!apiKey) {
        console.error(
          "REACT_APP_DAGSOL_DOCS_API_KEY is not defined in environment variables"
        );
        reject(new Error("API key for DAGSol is not configured"));
        return;
      }
      headers.append("x-api-key", apiKey);
      headers.append("Content-Type", "application/json");

      // Log the API key (masked for security)
      console.log(
        "Using API key:",
        `${apiKey.substring(0, 5)}...${apiKey.substring(apiKey.length - 5)}`
      );

      // Create request body
      const requestBody = {
        output_type: "chat",
        input_type: "chat",
        session_id: chatSessionId,
        tweaks: {
          "ChatInput-zTOoq": {
            input_value: message,
            session_id: chatSessionId,
            sender: "User",
            sender_name: "tadeo:" + email,
          },
          "Memory-1YzpB": {
            session_id: chatSessionId,
            sender_name: "tadeo:" + email,
          },
          "ChatOutput-Jfjcp": {
            session_id: chatSessionId,
            sender: "Machine",
            sender_name: "AI",
          },
        },
      };

      // Create request options
      const requestOptions = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(requestBody),
        redirect: "follow",
      };

      // Make the API call
      fetch(
        "https://langflow-rnp.hmg.apps.kloud.rnp.br/api/v1/run/794ea506-a5fa-4d73-a84e-84bd163e4b02?stream=false",
        requestOptions
      )
        .then((response) => {
          if (!response.ok) {
            console.error(
              "DAGSol API error response:",
              response.status,
              response.statusText
            );
            return response.text().then((text) => {
              try {
                // Try to parse as JSON for better error details
                const errorData = JSON.parse(text);
                console.error("DAGSol error details:", errorData);
                throw new Error(
                  `HTTP error! Status: ${response.status}, Details: ${JSON.stringify(errorData)}`
                );
              } catch (e) {
                // If not JSON, use the text response
                console.error("DAGSol error response text:", text);
                throw new Error(
                  `HTTP error! Status: ${response.status}, Response: ${text}`
                );
              }
            });
          }
          return response.json();
        })
        .then((data) => {
          // Process the response to match expected format
          const processedResponse = processDAGSolResponse(
            data,
            chatSessionId,
            message
          );
          resolve(processedResponse);
        })
        .catch((error) => {
          console.error("Error sending message to DAGSol:", error);
          reject(error);
        });
    } catch (error) {
      console.error("Exception in sendDAGSolMessage:", error);
      reject(error);
    }
  });
};

/**
 * Processes the DAGSol response to match the format expected by the application
 * @param {Object} responseData - The raw response from the DAGSol API
 * @param {string} sessionId - The session ID used in the request
 * @returns {Object} - Processed response with required fields
 */
function processDAGSolResponse(responseData, sessionId, userMessage) {
  try {
    // Extract the message text from the response
    const messageOutput = responseData.outputs[0].outputs[0];
    const messageText = messageOutput.results.message.text || "";

    // Create a response object that matches what the application expects
    return {
      answer: messageText,
      protocol: sessionId,
      historic: [], // You might want to handle chat history differently
      knowledge: [], // Adjust as needed
      suggestions: [], // No suggestions from this service
      summary:
        userMessage.length > 15
          ? userMessage.slice(0, 12) + "..."
          : userMessage,
    };
  } catch (error) {
    console.error("Error processing DAGSol response:", error);
    return {
      answer: "Sorry, there was an error processing the response from DAGSol.",
      protocol: sessionId,
      historic: [],
      knowledge: [],
      suggestions: [],
      summary: "Error in DAGSol Chat",
    };
  }
}
