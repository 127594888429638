/**
 * Proxy service for Langflow API communication
 * This file is referenced by the build process
 */

/**
 * Configuration for Langflow API endpoints
 */
const LANGFLOW_CONFIG = {
  baseUrl: "https://langflow-history.hmg.apps.kloud.rnp.br",
  endpoints: {
    messages: "/messages",
  },
};

/**
 * Fetch messages from Langflow API
 * @param {string} userEmail - User email to fetch messages for
 * @returns {Promise<Object>} - Promise resolving to message data
 */
async function fetchLangflowMessages(userEmail) {
  try {
    const url = `${LANGFLOW_CONFIG.baseUrl}${LANGFLOW_CONFIG.endpoints.messages}?sender_name=tadeo:${encodeURIComponent(userEmail)}`;

    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`Langflow API error: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching Langflow messages:", error);
    throw error;
  }
}

module.exports = {
  LANGFLOW_CONFIG,
  fetchLangflowMessages,
};
